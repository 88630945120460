import React  from "react";
import StoryDetail from "@components/storyDetail/StoryDetail";
import { graphql } from 'gatsby';
import Header from "@menus/header";

const CommunityStory = (props) => {
  const lang = props.pageContext.lang
  const story = props.data.communityStory

  return (
    <section>
      <Header location={props.location}/> 
        <StoryDetail 
          voices = {story.voices}
          storySummary = {story.summary}
          storySeoSummary = {story.seoSummary}
          storyTitle = {story.title}
          storyCover = {story.localImage.childImageSharp.gatsbyImageData}
          storyId = {story.id}
          premiumAudioRef = {story.premiumAudioRef}
          freeAudioRef = {story.freeAudioRef}
          sampleAudioRef = {story.sampleAudioRef}
          spiciness = {story.spiciness}
          scenario = {story.scenario.en}
          genre = {story.genre.en}
          fantasies = {story.fantasies}
          mainFantasy = {story.mainFantasy.en}
          slug={story.slug}
          seoTitle={story.seoTitle}
          seoDescription={story.seoDescription}
          duration={story.duration}
          premiumContent ={story.premiumContent}
          seriesStories={props.data.seriesStories}
          similarStories={props.data.similarStories}
          sameScenarioStories={props.data.sameScenarioStories}
          allStory={props.data.allStory}
          lang={lang}
          thumbnail={story.localImage.thumbnail}
          id={story.id}
          audiodesiresOriginal={story.audiodesiresOriginal}
        />
    </section>
  );
}; 

// this way will inject query into props.data
export const query = graphql`
query CommunityStoryQuery(
  $storyId: String, 
  $seriesStoriesIds: [String], 
  $similarStoriesIds: [String], 
  $sameScenarioStoriesIds: [String] )
  {
  communityStory(id: {eq: $storyId}) {
    audiodesiresOriginal
    id
    spiciness
    duration
    featured
    freeAudioRef
    premiumAudioRef
    premiumContent
    published
    sampleAudioRef
    seoDescription
    seoTitle
    slug
    seoSummary
    summary
    title
    voices{
      name
      slug
    }
    scenario {
      en {
        scenario
        slug
      }
    }
    genre {
      id
      en {
        genre
      }
    }
    localImage {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED,
          width: 600, 
          height: 600, 
          layout: CONSTRAINED)
      }
    }
    localImage {
      thumbnail: childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED,
          width: 120, 
          layout: CONSTRAINED)
      }
    }
    mainFantasy {
      en {
        fantasy
        slug
      }
    }
    fantasies {
      id
      languages
      en {
        fantasy
        slug
      }

    }
  }
  similarStories: allCommunityStory(filter: {id: {in: $similarStoriesIds}}) {
    edges {
      node {
        ...moreLikeThisCommunityStoryData
      }
    }
  }
  seriesStories: allCommunityStory(filter: {id: {in: $seriesStoriesIds}}) {
    edges {
      node {
        ...moreLikeThisCommunityStoryData
      }
    }
  }
  sameScenarioStories: allCommunityStory(filter: {id: {in: $sameScenarioStoriesIds}}) {
    edges {
      node {
        ...moreLikeThisCommunityStoryData
      }
    }
  }
}
`;  






export default CommunityStory;

